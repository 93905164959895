body {
    .config-container {
        margin: 10px 0 0 80px;
        margin: 24px 0 0 75px;
        // padding-bottom: 40px;
        .tech-specs-asset-hd {
            padding: 4px 10px;

            h4 {
                @include elementStyles($size: $font15, $fontFamily: $ibmplexMedium, $weight: 500);

                span {
                    font-family: $ibmplexLight;
                    font-weight: 300;
                }
            }

            .right-cta {
                float: right;
            }
        }

        .edit-basic-info {
            position: absolute;
            top: -10px;
            right: 0px;
            z-index: 10;
            height: 30px;
            text-align: right;  
            cursor: pointer;
            color: #fff;
            background: #fff;
            left: 0;
            width: 100%;
            padding-right: 4px;
            padding-top: 4px;
        }

        .tech-specs-card {
            position: relative;

            .tech-specs-nav {
                position: absolute;
                top: 0;
                left: 0;
                width: 180px;
                height: 100%;
                border-right: 1px solid #d7d7d7;
                // padding-top: 30px;
                background: #f1f1f1;

                .tech-nav-link {
                    border-bottom: 1px solid #d7d7d7;

                    // &:first-child{
                    //     border-top: 1px solid #d7d7d7;
                    // }
                    a {
                        @include elementStyles($size: $font13, $color: #000, $fontFamily: $ibmplexMedium, $weight: 500);
                        display: block;
                        padding: 6px 10px;

                        &.active {
                            color: #5b5bff;
                            background: #fff;
                            position: relative;
                            right: -1px;
                        }
                    }
                }
            }
            .tech-specs-content{
                margin: 10px 0px 0px 180px;
                padding: 10px;
                .aircraft-weight-table{
                    table{
                        thead{
                            tr{
                                background: #B5BEDB;
                                &:hover{
                                    background: #B5BEDB;
                                }
                                .header-cell-border{
                                    border-right: 1px solid #d7d7d7;
                                    padding: 5px;
                                    p{
                                        text-align: center;
                                        border-bottom: 1px solid #d7d7d7;
                                    }
                                    .inner-table-hd{
                                        th{
                                            padding: 0 4px;
                                            border: none !important;
                                        }
                                    }
                                }
                            }
                        }
                        tbody{
                            tr{
                                background: #fff !important;
                                .content-cell{
                                    padding: 5px;
                                    .inner-table{
                                        td{
                                            border: none;
                                            padding: 0 4px !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .chip-sub-asmbly{
                    padding: 2px 5px;
                    border-radius: 4px !important;
                    font-size: 13px;
                    background: #dddddd;
                    width: max-content;
                }
                .link-cls{
                    color: #007bff;
                    text-decoration: underline;
                    cursor: pointer;
                }
            }

            .status-1 {
                background: linear-gradient(90deg, #beed87, #d2e9b6 95%);
                color: #5ec264;
            }

            & .status-2 {
                background: linear-gradient(269deg, #ffdfcb, #ffb98d 95%);
                color: #ee7c26;
            }

            & .status-3 {
                background: linear-gradient(90deg, #a7c4ff, #729cf1 95%);
                color: #4c79d5;
            }

            & .status-4 {
                background: linear-gradient(180deg, #d7d7d7, #f6f6f6 95%, #fff);
                color: #333;
            }

            & .status-5 {
                background: linear-gradient(269deg, #e1e1e1, #b3b3b3);
                color: #2c2c2c;
            }

        }
        .config-lessee-section{
            .lessee-profile-input{
                input{
                    padding: 7px 13px;
                  }
            }
        }
    }
    }