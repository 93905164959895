body {
    .application-landing-page {

        // padding-bottom: 40px;
        h3 {
            @include elementStyles($size: $font16, $color: #5666f9, $fontFamily: $ibmplexMedium, $weight: 500, $margin: 0 0 6px 0);
        }

        .application-card {
            padding: 20px;
            background: #7d7561;
            box-shadow: 0 2px 2px rgba(0, 0, 0, .25);
            border-radius: 4px;
            color: #fff;
            min-height: 225px;
            position: relative;

            &.no-access-app {
                opacity: 0.85;
            }

            cursor: pointer;

            .lock-icon {
                position: absolute;
                top: 10px;
                right: 20px;
                z-index: 10;
            }

            .app-title {
                margin-bottom: 15px;

                img {
                    width: 50px;
                    margin-right: 15px;
                }

                h4 {
                    @include elementStyles($size: $font18, $fontFamily: $ibmplexMedium, $weight: 500);

                    sup {
                        color: #ab0202;
                    }
                }
            }

            .app-desc {
                p {
                    @include elementStyles($size: $font16, $fontFamily: $ibmplexRegular, $weight: 400);
                    margin-bottom: 10px;
                }
            }

            button {
                position: absolute;
                bottom: 10px;
                left: 20px;
                z-index: 20;
                color: #fff
            }

            ;

            .no-access-content {
                position: absolute;
                bottom: 10px;
                left: 20px;
                z-index: 20;
                color: #fff03b;
                @include elementStyles($size: $font13, $fontFamily: $ibmplexRegular, $weight: 400);
            }
        }
    }
    .engine-llp-table{
        // border-collapse: collapse;
            th{
                color:#000000;
                border: 1px solid #dedfe3;;
                background:#B5BEDB;
                font-size: 13px;
                text-align: center;
                min-width: 100px;
                .main-hd{
                    padding: 4px 0px;
                }
            }
            .multi-head{
                padding: 0px !important;
            }
            td{
                border: 1px solid #DEDFE3;
                min-width: 100px;
                tr{
                    background:transparent !important;
                    border:none;
                    td{
                        border: none;
                        border-right: 1px solid #DEDFE3;
                    }
                }
            }
}
.llp-thrust-table{
    .MuiFormControl-marginNormal{
        margin: 4px 0px !important;
    }
}
    .fleets-management, .reports-management{
        .smbcTabs {
            .MuiTabs-flexContainer {
                display: contents;
            }
        }
        .MuiTab-root{
            min-width: 100px;

            &.lg-tab {
                min-width: 150px !important;
            }

            .MuiTab-wrapper {
                font-size: 12px !important;
            }
        }

        .overflow {
            .MuiTabs-fixed {
                overflow: auto !important;
            }
        }

        .major-assemblies-list {
            .mui-table-format {
                tbody {

                    td,
                    th {

                        table,
                        th,
                        tr,
                        td {
                            border: none !important;
                            background: transparent !important;
                        }
                    }

                    td {
                        word-wrap: break-word;

                        .MuiChip-label {
                            font-size: 11px;
                            max-width: 130px;
                            display: inline-block;
                            word-wrap: break-word;
                        }
                    }
                }
            }

            .para-text {
                @include elementStyles($size: $font11, $fontFamily: $ibmplexRegular, $weight: 400);

                &.sub-status-bg {
                    border-radius: 4px;
                    background: rgb(230, 230, 230);
                    width: 100px;
                    display: inline-block;
                }
            }

            .asset-status {
                width: 100px;
                padding: 5px 0;
                border-radius: 4px;
                margin-bottom: 3px;
                display: inline-block;
                @include elementStyles($size: $font11, $fontFamily: $ibmplexRegular, $weight: 400);
                text-align: center;

                &.status-1 {
                    background-image: linear-gradient(90deg, #beed87, #d2e9b6 95%);
                    color: #5ec264;
                }

                &.status-2 {
                    background-image: linear-gradient(269deg, #ffdfcb, #ffb98d 95%);
                    color: #ee7c26;
                }

                &.status-3 {
                    background-image: linear-gradient(269deg, #a7c4ff, #729cf1);
                    color: #4c79d5;
                }

                &.status-4 {
                    background-image: linear-gradient(180deg, #d7d7d7, #f6f6f6 95%, #fff);
                    color: #333;
                }

                &.status-5 {
                    background-image: linear-gradient(269deg, #e1e1e1, #b3b3b3);
                    color: #2c2c2c;
                }
                &.status-13 {
                    background-image: linear-gradient(90deg, #6c757d, #9e9e9e 95%);
                    color: #ffffff;
                }
                &.status-14 {
                    background-image:linear-gradient(269deg, #a7c4ff 0%, #3a66be 100%);
                    color:#244382;
                }
                &.status-15 {
                    background-image:linear-gradient(269deg, #a08ad1 0%, #7450bb 100%);
                    color:#250f30;
                }
                &.status-16 {
                    background-image: linear-gradient(90deg, #808080, #b0b0b0 95%);
                    color: #ffffff;
                }
            
                &.status-17 {
                    //Evaluating
                    background-image: linear-gradient(90deg, #f1e577ee, #f2e784e9 95%);
                    color: #000000;
                }
            
                &.status-18 {
                    //Signed Lease
                    background-image: linear-gradient(90deg, #8bc34a, #aed581 95%);
                    color: #ffffff;
                }
                &.status-19 {
                    //In Storage
                    background-image: linear-gradient(90deg, #8b4513bd, #bc704daa 95%);
                    color: #ffffff;
                }
                &.status-20 {
                    background-image: linear-gradient(90deg, #489feac8, #59c5e9 95%);
                    color: #ffffff;
                }
            
                &.status-21 {
                    background-image: linear-gradient(90deg, #9ee0dcd7, #baeae893 95%);
                    color: #716672;
                }
                
            }

            .airframe-info {
                p {
                    @include elementStyles($size: $font11, $color: $black, $fontFamily: $ibmplexRegular, $weight: 400);
                }

                h4 {
                    @include elementStyles($size: $font13, $color: $black, $fontFamily: $ibmplexMedium, $weight: 500);
                }
            }
        }
    }

    .whats-new-container {
        width: 97.5%;
        margin-left: 56px;
        background-color: white;
        min-height: 93vh;

        .whats-new-header {
            position: fixed;
            width: 94vw;
            background-color: white;
            border-bottom: 1px solid #cccccc;
            font-size: 20px;
            padding: 16px 15px;
            color: #013e54;
        }

        .whats-new-body {
            padding: 82px 20px 20px 20px;

            .feature-card {
                margin-bottom: 20px;
                padding: 10px;

                .feature-date {
                    margin: 0px !important;
                }

                p {
                    margin-bottom: 2px;
                    font-size: 13px;
                }

                .feature-title-wn {
                    color: #0743b2;
                    margin: 20px 0px;
                }
            }
        }

        .faq-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            z-index: 10;
            position: fixed;
            width: 94vw;
            background-color: white;
            border-bottom: 1px solid #cccccc;
            font-size: 20px;
            padding: 0px 15px;
            color: #013e54;

            .MuiFormControl-marginNormal {
                width: 40%;
                margin-bottom: 8px !important;
                margin-top: 15px !important;
            }
        }

        .notification-header {
            padding: 16px 15px !important;
        }

        .MuiOutlinedInput-input {
            padding: 12px 14px;
        }

        .faq-body {
            padding: 64px 20px 20px 20px;

            .faq-car-title {
                color: #0743b2;
                margin: 25px 0px 10px 0px;
            }

            .faq-answer-section {
                font-size: 13px;

                ul {
                    margin-left: 14px !important;
                }
            }
        }

        .notifications-body {
            padding: 82px 20px 10px 20px;

            .read {
                color: #969595;
            }

            .unread {
                font-weight: 700;
            }

            .read-more-link {
                color: #2f8cff !important;
                font-family: Conv_IBMPlexSans-Medium;
                font-size: 12px;
                cursor: pointer;
            }

            .mark-all-read {
                text-align: right;
                color: #2f8cff !important;
                font-family: Conv_IBMPlexSans-Medium;
                font-size: 13px;
                margin-bottom: 5px;
                cursor: pointer;
            }
        }
    }

    .ntfcn-dtls-mdl {
        h4 {
            font-size: 16px;
            margin-bottom: 10px;
        }

        p {
            font-size: 12px;
            margin-top: 20px;
            border: 1px solid #353A4A;
            border-radius: 3px;
            padding: 10px;
            background: #DFE4E9;
            width: 100%;
        }
    }

    .asset-timeline-section {
        .vertical-timeline::before {
            background: #f1f0f0 !important;
        }

        .timeline-title {
            margin-bottom: 10px;
            padding: 10px;

            h4 {
                a {
                    display: flex;
                    align-items: center;

                    svg {
                        margin-right: 5px;
                    }

                    span {
                        @include elementStyles($size: $font18, $color: #5666f9, $fontFamily: $ibmplexMedium, $weight: 500, $margin: 0 0 6px 0);
                    }
                }
            }

            ul {
                li {
                    margin-right: 10px;

                    span {}
                }
            }
        }
    }
    .reports-management{
        .warning-info-text{
            font-size: 12px;
            text-align: center;
            width: auto!important;
        }
        .export-btn{
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            z-index: 10;
            background: #f1f1f1;
            padding: 5px;
            ul{
                float: right;
            }
            .selected-asset{
                @include elementStyles($size: $font13, $color: $black, $fontFamily: $ibmplexRegular, $weight:500);
            }
        }
        .custom-reports{
            padding: 15px 15px;
            overflow-y: auto;
            input[type="text"]{
                height: 0px;
            }
            .MuiPaper-root{
                .custom-rpt-card{
                    min-height: 200px;
                    .rpt-card-header{
                        background-color: #d9d9d9;
                        padding: 10px;
                        h4{
                            @include elementStyles($size: $font14, $color: $black, $fontFamily: $ibmplexMedium, $weight:600);
                            margin-bottom: 5px;
                        }
                        p{
                            @include elementStyles($size: $font11, $color: $black, $fontFamily: $ibmplexRegular, $weight:400);
                        }
                        .MuiButton-root{
                            float: right;
                            height: 25px;
                        }
                    }
                    .rpt-card-body{
                        padding: 10px;
                        .MuiFormLabel-root{
                            color:#000000;
                        }
                        .MuiChip-root{
                            height: 25px;
                            font-size: 11px;
                        }
                        .filter-by{
                            @include elementStyles($size: $font13, $color: $black, $fontFamily: $ibmplexRegular, $weight:400);
                        }
                        .lessor-select{
                            position: relative;
                            top: 15px;
                            @include elementStyles($size: $font11, $color: $black, $fontFamily: $ibmplexRegular, $weight:400);
                            text-align: right;
                            span{
                                color: #2f8cfe;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
    }
    .export-report-drawer{
        width: 500px;
        .MuiDrawer-paper{
            padding: 15px;
            .export-report-drawer-hd{
                width: 500px;
                h4{
                    @include elementStyles($size: $font18, $color: $black, $fontFamily: $ibmplexMedium, $weight:600);
                }
                margin-bottom: 15px;
            }
            .export-report-drawer-body{
                .export-module-card{
                    padding: 10px;
                    .rpt-card-header{
                        @include elementStyles($size: $font16, $color: $black, $fontFamily: $ibmplexMedium, $weight:500);
                        margin-bottom: 15px;
                    }
                    .rpt-card-body{
                        .MuiTypography-body1{
                            @include elementStyles($size: $font13, $color: $black, $fontFamily: $ibmplexRegular, $weight:400);
                        }
                    }
                }
            }
            .export-report-drawer-footer{
                padding: 25px 0 10px;
                ul{
                    float: right;
                }
            }
        }
        input[type="text"]{
            height: 0px;
        }
    }
}