body{
    
    .user-menu-list{
        .MuiPopover-paper{
            top: 46px !important;
            ul{
                li{
                    width: 140px;
                }
            }
        }
    }
    .primary-header{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        background: #fff;
        z-index: 999;
        padding: 2px 10px;
        border-bottom: 1px solid #d7d7d7 !important;
        .app-logo{
            img{
                width: 32px;
            }
        }
        .app-other-nav{
            display: flex;
            float: right;
            align-items: center;
            .header-right-item{
                margin-left: 10px;
                border-left: 1px solid #d7d7d7;
                padding-left: 10px;
                &:first-child{
                    margin-left: 0;
                    padding-left: 0;
                    border-left: none;
                }
            }
            .short-icons{
                margin-right: 5px;
                ul{
                    display: flex !important;
                    align-items: center !important;
                    justify-content: center !important;
                    li{
                        cursor: pointer;
                        margin-left: 8px;
                        padding-left: 8px;
                        &:first-child{
                            margin-left: 0;
                            padding-left: 0;
                        }
                    }
                }
            }
            .console-management-modal{
                .MuiOutlinedInput-input{
                    padding: 10px 14px !important;
                }
                .MuiAutocomplete-root{
                    width: 240px;
                }
            }
            .user-details{
                display: flex;
                align-content: center;
                cursor: pointer;
                .MuiList-root{
                    top: 45px !important;
                }
                .MuiAvatar-root{
                    margin-right: 5px;
                }
                h5{
                    @include elementStyles($size: $font15, $color:#000, $fontFamily: $ibmplexMedium, $weight:500);
                }
                p{
                    @include elementStyles($size: $font11, $color:#000, $fontFamily: $ibmplexRegular, $weight:400);
                }
            }
        }
    }
    .primary-side-nav{
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 3;
        transition: all .5s ease;
        width: 80px;
        background: #6b5eb0;
        transition: all .5s cubic-bezier(.075,.82,.165,1);
        padding-top: 60px;
        .menu-link{
            text-align: center;
            a{
                display: block;
                padding: 8px 3px;
                img{
                    margin-bottom: 4px;
                }
                p{
                    @include elementStyles($size: $font11, $color:#fff, $fontFamily: $ibmplexRegular, $weight:400);
                }
                &.active{
                    background: rgba(0, 0, 0, .2);
                }
            }
        }
    }
    @media (min-width: 992px) and (max-width:1200px) {
        header{
            display: block !important;
        }
    }
}