
.list-inline{
    .hover-inline{
        cursor: pointer;
    }
}
.multiline-clamp{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.singleline-clamp{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.empty-collection-ui{
    padding: 40px 0;
    h4{
        @include elementStyles($size: $font18, $color: $black, $fontFamily: $ibmplexRegular, $weight:400);
    }
}
input:disabled {
    cursor: not-allowed !important;
}
.page-loader{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 9999;
    background: rgba(0,0,0,0.1);
    overflow: hidden;
    cursor: not-allowed;
    span{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        img{
            width: 100px;
        }
    }
}
.console-forms-fields{
    input[type="text"], textarea, input[type="number"]{
        height: auto !important;
    }
    .MuiInputBase-multiline{
        padding: 5px !important;
    }
    .MuiFormControl-marginNormal{
        margin-bottom: 0;
    }
    .MuiOutlinedInput-input{
        padding: 14px !important;
        @include elementStyles($size: 15px, $color: $black, $fontFamily: $ibmplexRegular, $weight:400)        
    }
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]{
        padding: 0;
    }
    .MuiTextField-root {
        label{
            @include elementStyles($size: 14px, $color: $black, $fontFamily: $ibmplexMedium, $weight:500);
        }
    }
}
.revision-history-drawer{
    .history-head{
        border-bottom: 1px solid #d7d7d7;
        display: flex;
        padding: 10px;
        h4{
            @include elementStyles($size: $font16, $color: $black, $fontFamily: $ibmplexMedium, $weight:500);
        }
        span{
            margin-left: auto;
            cursor: pointer;
        }
    }
}
.read-more-less-blk{
    .read-more-txt{
        color: #0E7FE1;
        font-size: 12px;        
        cursor: pointer; 
    }
}
.edit-revision-history{
    margin-bottom: 15px;
    padding: 20px 20px 20px 30px;
    overflow: auto;
    
    h6,p{
        font-size: 12px;
        margin-bottom: 5px;
    }
    .label-value-card{
        p{
            font-family: $ibmplexSemiBold;
        }
    }
    ul{
        border-left: 1px solid #8b36e1;
        padding-left: 20px;
        li{
            position: relative;
            .timeline-icon{
                position: absolute;
                left: -35px;
                top: 0;
                z-index: 10;
                img{
                    width: 27px;
                    background: #ffffff;
                }
            }
            background: #fff;
            box-shadow: 0 4px 16px 0 #e6e6e6;
            border-radius: 6px;
            padding: 15px;
            margin-bottom: 20px;
            table{
                width: 100%;
            }
        }
    }
    .edit-detail-table{
        overflow: auto;
        table{
            width: 100%;
            border-collapse: separate;
            thead{
                th{
                    padding:4px 20px;
                    font-family: $ibmplexRegular;
                    font-size:12px;
                    color:#a0a0a0;
                    border:1px solid #ccc;
                    width:80px !important;
                }
            }
            tbody{
                th{
                    padding:4px 20px;
                    font-family: $ibmplexRegular;
                    font-size:12px;
                    color:#a0a0a0;
                    border:1px solid #ccc;
                    width:80px !important;
                }
                td{
                    width:80px !important;
                    padding:4px 20px;
                    font-family: $ibmplexRegular;
                    font-size:12px;
                    color:#000000;
                    border:1px solid #ccc;
                    width:80px !important;
                    span{
                        color:#000000 !important;
                    }

                }
            }
        }
    }
}
.deployment-info{
    background: #fff1b6;
    padding: 5px;
    border: 1px solid #a78701;
    border-radius: 4px;
    margin-bottom: 10px;
    // display: flex;
    text-align: center;
    justify-content: space-between;
    align-items: center;
    span{
      color: #a78701;
      font-size: 12px;
      font-family: $ibmplexSemiBold;
      h6{
        color:#478ddc;
        font-size: 12px;
        display: inline;
      }
    }
    img{
      float: right;
      cursor: pointer;
      color: #ff0000;
      margin-top: 2px;
    }
  }